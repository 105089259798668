/* --------------------------- /////////// VII. TEAM SECTION /////////// --------------------------- */
.team {
    padding-top: 100px;
}

.full-row {
    width: 100%;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.team figure {
    border-bottom: 2px solid #E84118;
    margin: 0 10px;
    max-height: 375px;
    overflow: hidden;
}

.team-caption ul {
    margin-top: 10px;
}

.team-caption ul li {
    display: inline;
    margin: 0 5px;
}

.team-caption ul li > a {
    color: #444;
}

.team-caption ul li > a:hover {
    color: #E84118;
}

.team-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    text-align: center; 
    z-index: 5;
    opacity: 0;
    transition: all 0.3s ease;
    width: 100%;
    padding: 0 9px;
}

.team figure:hover > .team-caption {
    opacity: 1;
}

.team figure:hover > img {
    filter: blur(2px);
}

.team-name {
    font-size: 1.5rem;
    padding-bottom: 0;
}

.gallery-item {
    padding: 0 2.5px;
}

.grid-gallery .row {
    margin: 0 0 5px 0;
}

.overlay.gallery {
    left: 0;
    width: 100%;
    border: none;
}

/* --------------------------- /////////// XII. RESPONSIVE STYLES /////////// --------------------------- */

@media (min-width : 767px) {
    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 20px;
        margin-top: 50px;
    }

    .team figure {
        overflow: hidden;
    }

    .team-card {
        max-width: 25%;
    }
    
    .gallery-item {
        margin: 5px 0;
    }

    .grid-gallery .row {
        margin: 0;
    }
}