/* --------------------------- /////////// III. HEADER & NAVIGATION /////////// --------------------------- */

nav {
    z-index: 9999;
}

.navbar-blur {
    /* backdrop-filter: blur(15px); */
    backface-visibility: hidden;
}

.logo {
    font-family: Anurati, sans-serif;
    font-size: 1.75rem; /* 28px */;
    letter-spacing: 5px;
}
.navbar-light {
    background-color: rgba(255,255,255,0);
    border: 1px solid rgba(255,255,255,0);
    z-index: 900;
    transition: 0.3s;
}

.nav-link {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 5px;
    transition: 0.2s;
}
.navbar-light .navbar-nav .nav-link {
    color: #0a0a0a;
}


.navbar-light .navbar-nav .nav-link:active {
    color: #E84118;
}
.navbar-light .navbar-nav .nav-link:focus {
    color: #E84118;
}
.navbar-toggler {
    border: none;
    cursor: pointer;
}

.hide-navbar {
    width: 100%;
    height: 100%;
    z-index: 999;
    color: #181818;
    background: black;
}

.hide-navbar-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
}

li > a {
    font-size: 1.3rem!important;
}

.hamburger .icon-bar {
    display: block;
    height: 2px;
    width: 25px;
    background: #222;
    margin: 7px 0;
    transition: .3s ease-in-out;
}

/* - Mobile menu animation - */
.hamburger .icon-bar:nth-child(1) {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}
.hamburger .icon-bar:nth-child(2) {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}
.hamburger .icon-bar:nth-child(3) {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}
.hamburger.open .icon-bar:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hamburger.open .icon-bar:nth-child(2) {
    width: 0%;
    opacity: 0;
}
.hamburger.open .icon-bar:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 190px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 9999;
    text-decoration: none;
}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

/* --------------------------- /////////// XII. RESPONSIVE STYLES /////////// --------------------------- */

@media (max-width : 991px) {
    .navbar-light .navbar-nav .nav-link {
        color: #222;
    }
    .navbar-light .navbar-nav .active>.nav-link {
        color: #222;
    }
    .navbar-light{
        background-color: rgba(255,255,255,0.7); /* navbar background gradient */
    }
    .scrolled {
        background-color: rgba(255,255,255,1); /* navbar background gradient */
    }
}

@media (max-width : 512px) {
   .dropdown-content {
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: #212529;
        width: 200px;
    }

    .dropdown:hover .dropdown-content {
        display: flex;
    }

    .dropdown a {
        text-decoration: none;
        color: #f9f9f9!important;
    }

}