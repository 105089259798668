/* --------------------------- /////////// IX. CONTACT SECTION /////////// --------------------------- */

.contact {
    padding-top: 100px;
}

.contactForm {
    /* background: #FAFAFA; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 3%;
    margin-top: 25px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.11);
}

.contactUsContainer {
    width: 50%;
}

.contactUs {
    width: 100%;
}

.contactForm_message {
    width: 50%;
}

.contactUs input {
    width: 75%;
}

.contactForm input {
    color: #222;
    background: none;
    border-bottom: 2px solid #E84118 !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    margin-bottom: 25px;
}
.contactForm textarea {
    width: 90%;
    height: 95%;
    color: #222;
    background: none;
    border-bottom: 2px solid #E84118 !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    margin-bottom: 25px;
}
.contactForm .button {
    margin-left: 0;
    padding: 15px 25px;
    font-size: 1.25rem;
}
/* - Warning and succes msg text- */
.text-danger, .text-success {
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.25rem;
}
.contact-info {
    margin-top: 25px;
}
.contact ul li i {
    color: #E84118;
    background: #FAFAFA;
    padding: 10px; 
}
.contact ul li p {
    display: inline;
    margin-left: 5px;
}
.contact ul li {
    margin-bottom: 15px;
}

@media (max-width : 500px) {
    .contactUsContainer {
        width: 100%;
    }
    .contactUs {
        display: flex;
        justify-content: center;
    }

    .contactUs input {
        width: 95%;
    }
 
    .contactForm_message {
        width: 100%;
    }

    .contactForm_message textarea {
        height: 200px;
        width: 95%;
    }
}