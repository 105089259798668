/* --------------------------- /////////// HOME CONTAINER /////////// --------------------------- */
.home-container {
  position: relative; /* Pour que les éléments positionnés absolument à l'intérieur de ce conteneur soient relatifs à lui */
  height: 90vh; /* 90% de la hauteur de la fenêtre */
  width: 100vw; /* 100% de la largeur de la fenêtre */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
}

.video-player {
  display: flex;
  align-items: flex-end;
  height: 70%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  z-index: 1; /* Assurer que la vidéo soit derrière le texte */
  overflow: hidden;
}

.video-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 26px;
}

.video-box--title > img {
  width: 50%;
  padding-top: 0px;
}

.iframe-steam {
  width: 100%;
  height: 100%;
}


/* --------------------------- /////////// HOME HOVER (superposition) /////////// --------------------------- */
.home-hover {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Pour que cet élément soit au-dessus de la vidéo */
}

.video-box--title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px !important;
  width: 100%;
  margin-bottom: 0px !important;
  z-index: 60; /* Assurer que le texte soit au-dessus de tout le reste */
  text-decoration: none !important;
}

/* --------------------------- /////////// RESPONSIVE STYLES /////////// --------------------------- */
@media (min-width: 768px) {
  .video-player {
    height: 100%;
  }

  .home-hover {
    align-items: center;
    justify-content: space-around;
    position: absolute; /* Positionnement absolu pour superposer cet élément au-dessus de la vidéo */
    width: 100%;
    height: 100%;
    z-index: 2; /* Pour que cet élément soit au-dessus de la vidéo */
  }

  .video-box--title {
    justify-content: center;
    width: 100%;
    border-top: inherit;
    display: flex;
    align-items: flex-end;
  }

  .video-box--title > img {
    width: 30%;
    padding-top: 0px;
  }

  .iframe-steam {
    width: 35%;
    height: 25%;
  }
}
