* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  .gamer-card {
    width: 350px;
    max-width: 100%;
    margin: 0 20px 56px 0px;
    min-height: 270px;
    height: auto;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.11);
    padding: 15px;
  }
  
  h3 {
    font-size: 1em !important;
    font-weight: 700;
  }
  
  p {
    font-size: 1em;
    line-height: 1.7;
    font-weight: 300;
    color: var(--text-gray);
  }
  
  .description {
    white-space: wrap;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .wrap {
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 24px;
    padding: 24px;
    flex-wrap: wrap;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    background: #fff;
    width: 24%;
    padding: 10px!important;
  }
  
  .box-top {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
    
  }
  
  .box-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 50% 20%;
  }
  
  .title-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .box-title {
    border-left: 3px solid #E84118 ;
    padding-left: 12px;
    padding-top: 5px;
    margin-top: 5px;
  }
  
  
  /* RESPONSIVE QUERIES */
  
  @media (min-width: 320px) {
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
   
  }
  
  @media (min-width: 460px) {
    .title-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
   
  }
  
  @media (min-width: 640px) {
    .box {
      flex-basis: calc(50% - 12px);
    }
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    
  }
  
  @media (min-width: 840px) {
    .title-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
   
  }
  
  @media (min-width: 1024px) {
    .box {
      flex-basis: calc(33.3% - 16px);
    }
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
   
  }
  
  