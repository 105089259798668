/* --------------------------- /////////// X. FOOTER /////////// --------------------------- */
.footer {
    width: 100%;
    min-height: 70px;
    height: auto;
    background: rgba(255, 255, 255, 0.1); /* 0.8 pour 80% d'opacité */
    border-top: 2px solid #E84118;
    padding: 20px 0 0 0;
    z-index: 99;
}

.copyright {
    font-size: 0.8em;
    margin-bottom: 0!important;
}

@media (max-width : 991px) {
    .footer {
        text-align: center;
    }
    .social-links {
        float: none;
    }
    .copyright {
        float: none;
    }
}