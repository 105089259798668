/* --------------------------- /////////// VII. TEAM SECTION /////////// --------------------------- */
.services {
    padding-top: 100px;
}

/* .container {
    max-width: 90%!important;
} */

/* --------------------------- /////////// XII. RESPONSIVE STYLES /////////// --------------------------- */

@media (min-width : 767px) {
    .row-card {
        display: flex;
    }
}